<template>
  <div class="col-12 col-lg-3" data-html2canvas-ignore="true">
    <div class="box-white-fiche">
        <div class="save-search box-height">
            <ul class="list-checkboxs"> 
                <!-- select all -->
                <li class="select_all"> 
                    <label>          
                        <input class="menu-checkbox" type="checkbox" v-model="select_all" @click="select_all_check()">
                        <span class="check-therapeutic"></span>         
                        <div> {{$t('check_uncheck')}} </div>
                    </label>
                </li>
                <template v-for="(item, key) in list_check_box" >
                    <li v-if="item.enable" :id="'check_box_detail_'+$route.params.agency+'_'+key" :key="key">
                        <label>          
                            <input class="menu-checkbox" type="checkbox" :value="key" v-model="show_block" @click="scroll(key)">
                            <span class="check-therapeutic"></span>         
                            <div :class="item.orange_text ? 'text-orange' : ''"> {{$t(item.title)}}</div>
                        </label>
                    </li> 
                </template>                           
                <template v-for="(item, key) in links" >
                    <li v-if="!disable_links.includes(key)"  :id="'check_box_detail_link_'+key" :key="key"> 
                        <label>          
                            <input class="menu-checkbox" type="checkbox" :value="'link_agency_'+key" v-model="show_block" @click="scroll('link_agency_'+key)">
                            <span class="check-therapeutic"></span>         

                            <div v-if="item.icon_flags == ''"> {{item.title}} </div>
                            <div v-else class="flag-icons">
                                <img :alt="item.agency_model" :src="require('../../assets/images/flag/new/'+item.icon_flags+'.svg')" width="20" height="20" style="margin-right: 5px;" />
                                
                                <!-- if link of Prix -->
                                <template v-if="key.startsWith('Prix')"> Drugs </template> 
                                <template v-else> {{item.title}} </template>                                
                            </div>

                        </label>
                    </li>
                </template>
            </ul>
        </div>
        <FreeSearch/>
        <div class="save-my-search main-overlay load-excel-overlay" @click="save_selection" v-if="show_block.length && data.agency_model">
            <div class="box-save-search-detail m-0">
                {{$t('Save selection')}}
            </div>
            <div class="overlay" v-if="loading">
                <div class="loadding_circle"></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import FreeSearch from './FreeSearch.vue'
export default {
    name : "LeftSection",
    components : {
        FreeSearch
    },
    data(){
        return {
            disable_links : ["EconomicEvaluation","BoitePresentation","PrixNewZealandGroup"],
            loading : false
        }
    },
    props : {
        list_check_box : {
            style : Object
        },
        links : {
            style : Object
        }
    },
    computed : {
        show_block : {            
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        select_all : { 
            set(bool) { this.$store.dispatch('detail/set_select_all', bool)},
            get() {return this.$store.getters['detail/select_all']}
        },         
        agency(){
            return this.$store.getters['detail/agency']
        }, 
        view_enabled(){
            return this.$store.getters['detail/view_enabled']
        },
        data(){
            return this.$store.getters['detail/data']
        },
        block_remove_check(){
            let block_remove = []
            for (let key of this.enable_block_checked){
                if (!this.show_block.includes(key)){
                    block_remove.push(key)
                }
            }                  
            return block_remove
        },
        enable_block_checked(){
            let block_list = []
            for (let key in this.list_check_box){
                if(this.list_check_box[key].enable){      
                    block_list.push(key)
                }                  
            }                  
            block_list = block_list.concat(this.list_of_link_checked)
            return block_list
        },
        list_of_link_checked(){
            let links_list = []
            for(let key in this.links){              
                if (!this.show_block.includes('link_agency_'+key) && !this.disable_links.includes(key)){                       
                    links_list.push('link_agency_'+key)
                }
            }
            return links_list
        }

    },
    mounted(){
        if(this.agency == 'BoitePresentation'){
            this.show_block = this.enable_block_checked
        } else if(this.view_enabled.length){
            this.show_block = this.view_enabled.filter(value => this.enable_block_checked.includes(value))
        } 

        if(!this.show_block.length){
            let default_show_block = ['detail_information_general', 'detail_general_information', 'detail_indication', 'detail_indication_under_review_original', 'detail_key_documents', 'detail_Conclusion']
            if(this.agency === 'AtuFrance'){
                default_show_block = ['detail_indication_atu', 'detail_Synthese', 'detail_general_information', 'detail_key_documents']
            }

            for (let key in this.list_check_box){
                if(this.list_check_box[key].enable && default_show_block.includes(key)){
                    this.show_block.push(key)
                }
            }  
            this.show_block = this.show_block.concat(this.list_of_link_checked)
        }
        
    },
    methods : {  
        save_selection(){
            this.loading = true

            let block_remove =  this.block_remove_check

            console.log(this.show_block)
            console.log(block_remove)

            this.$store.dispatch("detail/update_view_enabled", { agency_id :this.data.agency_id , block_enabled : this.show_block, block_remove:block_remove}).then(()=> {
                alert(this.$t('view_enable_success'));
                this.loading = false
            }) 

        },
        select_all_check(){
            this.select_all = !this.select_all
            let temp_list_check_box = this.list_check_box
            if(this.select_all){
                let show_block_temp = []
                for(let key in temp_list_check_box){
                    if(temp_list_check_box[key].enable){
                        //this.list_check_box[key].show = true
                        show_block_temp.push(key)
                    }
                }
                for(let key in this.links){    
                   if (!this.disable_links.includes(key)){                
                        show_block_temp.push('link_agency_'+key)
                   }
                }
                this.show_block = show_block_temp
            }else{
                this.show_block = []
            }
        }, 
        scroll(id){
            setTimeout(() => {  
                const element = document.getElementById(id);
                if(element){
                    const offset = 75;
                    const bodyRect = document.body.getBoundingClientRect().top;
                    const elementRect = element.getBoundingClientRect().top;
                    const elementPosition = elementRect - bodyRect;
                    const offsetPosition = elementPosition - offset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });

                    element.classList.add('focus');
                    setTimeout(() => { element.classList.remove('focus');  }, 1000)
                }
            }, 100);
        }
    },
    watch : {
        show_block(){
            let count = 0
            let count_link = 0
            for(let key in this.list_check_box){
                if(this.list_check_box[key].enable){
                    count += 1
                }
            }
            for(let key in this.links){    
                if (!this.disable_links.includes(key)){                
                    count_link += 1
                }
            }

            if(this.show_block.length === (count + count_link) &&  this.show_block.length !== 0){
                this.select_all = true
            }else{
                this.select_all = false
            }
        }
        
    },
}
</script>

<style>

</style>