<template>
    <div class="list-free-search">
        <ul style="padding-left:0px">
            <li class="active">
                <div class="input-text-search" style="width:100%">
                    <p>Free search</p>
                    <input id="keyword" @keyup.8="removeHighlight(false)" @keyup.enter="search" type="text" placeholder="Write here"
                            autocomplete="off">
                    <div class="icon-top-bar" id="submit-freesearch" @click="search" style="cursor:pointer;">
                        <div class="icon-search-html"></div>
                    </div>
                </div>
            </li>
            <li class="box-checkboxes">
                <div class="save-search box-height">
                    <ul class="list-checkboxs">
                        <li><label><input type="checkbox" v-model="type_freesearch_1">
                                <span class="check-therapeutic"></span>
                                <div v-html="$t('all_of_the_words')"></div>
                            </label></li>
                        <li><label><input type="checkbox" v-model="type_freesearch_2">
                                <span class="check-therapeutic"></span>
                                <div v-html="$t('exact_phrase')"></div>
                            </label></li>
                        <li><label><input type="checkbox" v-model="type_freesearch_3">
                                <span class="check-therapeutic"></span>
                                <div v-html="$t('one_of_the_words')"></div>
                            </label></li>
                    </ul>
                </div>

                <div class="div-search-result">
                    <p id="count_result" style="margin-top: 20px; margin-bottom:0;"></p>
                    <ul id="ul-search-result" v-if="item_highlight.length > 0">
                        <li style="cursor:pointer;" @click="find_highlight(key)" v-for="(item, key) in item_highlight" :key="key" v-html="item"></li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'FreeSearch',
    data(){
        return{
            type_freesearch_1 : true,
            type_freesearch_2 : false,
            type_freesearch_3 : false,
            keyword_search : '',
            count_highlight : 0,
            item_highlight : [],
            refresh : false
        }
    },
    watch : {
        type_freesearch_1(){
            if(this.type_freesearch_1 === true){
                this.type_freesearch_2 = false;
                this.type_freesearch_3 = false;
            }
            this.refresh_search()
        },
        type_freesearch_2(){
            if(this.type_freesearch_2 === true){
                this.type_freesearch_1 = false;
                this.type_freesearch_3 = false;
            }
            this.refresh_search()
        },
        type_freesearch_3(){
            if(this.type_freesearch_3 === true){
                this.type_freesearch_1 = false;
                this.type_freesearch_2 = false;
            }
            this.refresh_search()
        },
        keyword_search(){
            if(this.keyword_search !== ''){
                document.querySelector("p#count_result").style.borderBottom = "1px solid #000000";
                document.querySelector("p#count_result").style.display = "block";
            }else{
                document.querySelector("p#count_result").style.borderBottom = "0px solid";
                document.querySelector("p#count_result").style.display = "none";
            }
        },
        item_highlight(){
            document.querySelector("p#count_result").innerHTML = this.item_highlight.length+' Results' 
        },
        show_block(){
            this.refresh_search()
        }
    },
     computed : {
        show_block : {            
            get() {return this.$store.getters['detail/show_block']}
        }
    },
    methods : {
        search(){
            
            if (document.getElementById('keyword').value.length === 0 || (this.type_freesearch_1 === false && this.type_freesearch_2 === false && this.type_freesearch_3 === false)) {
                    alert('Champ recherche ne peut pas être vide');
            }else if(document.getElementById('keyword').value != this.keyword_search || this.refresh){
                this.keyword_search = document.getElementById('keyword').value
                this.removeHighlight();
                this.refresh = false
                let arr_keyword = [];
                
                if (this.type_freesearch_1 === true || this.type_freesearch_3 === true) {
                    if (this.keyword_search.indexOf(" ") !== -1) {
                        arr_keyword = this.keyword_search.split(" ");
                    } else if (this.keyword_search.indexOf(",") !== -1) {
                        arr_keyword = this.keyword_search.split(",");
                    } else {
                        arr_keyword.push(this.keyword_search);
                    }
                } else if (this.type_freesearch_2 === true) {
                    arr_keyword.push(this.keyword_search);
                }

                let chk = true;
                if (this.type_freesearch_1 === true) {
                    for (var i = 0; i < arr_keyword.length; i++) {
                        if (!this.test_found(document.getElementById('detail'), arr_keyword[i])) {
                            chk = false;
                        }
                    }
                }
                // console.log(arr_keyword)
                if (chk) {
                    for (let key in arr_keyword) {
                        this.CustomHighlight(document.getElementById('detail'), arr_keyword[key])
                    }
                }
            }
        },

        test_found(b, c) {
            var d = 0;
            if (3 === b.nodeType) {
                var a = b.data.toUpperCase().indexOf(c.toUpperCase());
                if (0 <= a) {
                    d = 1;
                }
            } else if (1 === b.nodeType && b.childNodes && !/(script|style)/i.test(b.tagName)) {
                for (a = 0; a < b.childNodes.length; ++a) {
                    var f = this.test_found(b.childNodes[a], c);
                    if (f === 1) {
                        d = 1;
                        break;
                    }
                    a += f;
                }
            }
            return d;
        },

        CustomHighlight(b, c){
            var d = 0;
            if (3 === b.nodeType) {
                var a = b.data.toUpperCase().indexOf(c.toUpperCase());
                if (0 <= a) {
                    d = document.createElement("span");
                    d.className = "highlight";
                    var attr_id = "highlight_" + this.count_highlight
                    this.count_highlight++
                    d.setAttribute("id", attr_id);
                    var temptxt = b.data;
                    var tempPositionFound = a;

                    a = b.splitText(a);
                    a.splitText(c.length);
                    var f = a.cloneNode(!0);
                    d.appendChild(f);
                    a.parentNode.replaceChild(d, a);
                    d = 1;


                    var positionStart = (tempPositionFound - 50) < 0 ? 0 : tempPositionFound - 50;
                    var positionEnd = tempPositionFound + c.length + 50;
                    var txtResult = temptxt.substring(positionStart, positionEnd);
                    if (positionStart !== 0) {
                        txtResult = txtResult.substring(txtResult.indexOf(" "));
                    }
                    if (txtResult.length > 100) {
                        txtResult = txtResult.substring(0, txtResult.lastIndexOf(" "));
                    }
                    txtResult = txtResult.trim();
                    if (txtResult !== "") {
                        this.item_highlight.push(this.highlightResult(txtResult, c));
                    }
                }
            } else if (1 === b.nodeType && b.childNodes && !/(script|style)/i.test(b.tagName))
                for (a = 0; a < b.childNodes.length; ++a)
                    a += this.CustomHighlight(b.childNodes[a], c);
                    
            return d;
        },

        removeHighlight(search = true){
            this.item_highlight = []
            this.count_highlight = 0
            document.querySelectorAll("span.highlight").forEach(
                function(item){
                    item.parentNode.replaceChild(item.firstChild,item)
            })
            if(!search){
                this.keyword_search = ''
            }
            document.getElementById('detail').normalize()
        },

        highlightResult(txtResult, c) {
            var positionF = txtResult.toUpperCase().indexOf(c.toUpperCase());
            var tempTxt = txtResult, tempTxt2 = txtResult, tempTxt3 = txtResult;
            tempTxt3 = tempTxt3.substring(positionF);
            return tempTxt.substring(0, positionF) + '<span class="text-orange">' + tempTxt3.substring(0, c.length) + '</span>' + tempTxt2.substring(positionF + c.length);
        },

        find_highlight(highlight_id) {
            document.querySelectorAll("span.highlight").forEach(
                function(item){
                    item.style.backgroundColor = 'yellow';
                }
            );
            let e = document.getElementById('highlight_'+highlight_id);
            e.style.backgroundColor = 'orange'
            e.scrollIntoView(true)

            if(window.scrollY){
                window.scrollTo({   
                    top : window.scrollY - 120,
                    behavior: "smooth"
                });
            }
        },
        refresh_search(){
            if(this.keyword_search){
                this.removeHighlight(false);
                this.refresh = true
            }
        }
    }
}
</script>

<style>
.highlight{
    background-color: yellow;
}

#ul-search-result{
    padding-left : 0px;
}
#ul-search-result li{
    border-bottom: 1px solid #968d8d;
}
</style>